/** @format */

import $ from "jquery";

($ as any).validator.methods.email = function(value, element) {
    value = value.trim();

    const isValid =
        this.optional(element) ||
        /^((([a-zA-Z]|\d|[!#\$%&amp;&#39;\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&amp;&#39;\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-zA-Z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/.test(
            value
        );

    return isValid;
};

($ as any).validator.addMethod(
    "postcode",
    function(value, element) {
        value = value.trim();
        let re = new RegExp(
            /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/
        );
        let currency = $(element).data("currency") || "GBP";

        if (currency === "USD") {
            re = new RegExp(/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/);
        } else if (currency === "CAD") {
            re = new RegExp(
                /^[abceghj-nprstvxyABCEGHJ-NPRSTVXY]\d[abceghj-nprstv-zABCEGHJ-NPRSTV-Z][ -]?\d[abceghj-nprstv-zABCEGHJ-NPRSTV-Z]\d$/
            );
        } else if (currency === "AUD") {
            re = new RegExp(
                /^[0-9]{4}$|^[0-9]{4}[ ]{1}[a-zA-Z]{2}$|^[a-zA-Z]{1,2}[0-9]{4}$|^[a-zA-Z]{1,2}[0-9]{4}[ ]{1}[a-zA-Z]{2}$/
            );
        }

        return this.optional(element) || re.test(value);
    },
    "That postcode doesn't look quite right..."
);

($ as any).validator.addMethod(
    "vrm",
    function(value, element) {
        value = value.split(" ").join("");
        value = value.toUpperCase();

        let re = new RegExp(
            "^[A-Z]{2}[0-9]{2}[A-Z]{3}$|^[A-Z][0-9]{1,3}[A-Z]{3}$|^[A-Z]{3}[0-9]{1,3}[A-Z]$|^[0-9]{1,4}[A-Z]{1,2}$|^[0-9]{1,3}[A-Z]{1,3}$|^[A-Z]{1,2}[0-9]{1,4}$|^[A-Z]{1,3}[0-9]{1,3}$"
        );

        return this.optional(element) || re.test(value);
    },
    "Please enter a valid reg number"
);

export function fullNameTest(
    value: string,
    element?: JQuery<HTMLElement>
): boolean {
    return /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)$/.test(
        value
    );
}

($ as any).validator.addMethod(
    "fullname",
    fullNameTest,
    "First and last name required."
);

($ as any).validator.addMethod(
    "ukPhone",
    function(value, element) {
        let standardRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(
            value
        );
        let valueWithoutSpaces = value.split(" ").join("");
        let notAllZeros = valueWithoutSpaces !== "00000000000";

        return this.optional(element) || (standardRegex && notAllZeros);
    },
    "That phone number doesn't look quite right..."
);

($ as any).validator.addMethod(
    "dynamicPhone",
    function(value, element) {
        let re = new RegExp(
            /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
        );
        let currency = $(element).data("currency") || "GBP";

        let valueWithoutSpaces = value.split(" ").join("");
        let notAllZeros = valueWithoutSpaces !== "00000000000";

        if (currency === "USD" || currency === "CAD") {
            re = new RegExp(
                /^\+?(\d{1,2})?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
            );
        } else if (currency === "AUD") {
            re = new RegExp(
                /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/
            );
        }

        return this.optional(element) || (re.test(value) && notAllZeros);
    },
    "That phone number doesn't look quite right..."
);

($ as any).validator.addMethod(
    "hasAttribute",
    function(value, element, param) {
        return $(element).attr(param) !== undefined;
    },
    "Please select one of the options."
);

($ as any).validator.addMethod(
    "notInOtherInput",
    function(value, element, param) {
        let inputElem = $(`[name="${param}"]`);
        let inputVal = inputElem.val() as string;

        if (!inputVal) {
            return true;
        }

        let firstPart = inputVal.split(" ")[0];

        let allInOtherInput = value
            .toLowerCase()
            .includes(inputVal.toLowerCase());
        let firstPartInOtherInput = value
            .toLowerCase()
            .includes(firstPart.toLowerCase());

        return !allInOtherInput && !firstPartInOtherInput;
    },
    "Please enter your pet's name, not your name."
);

($ as any).validator.addMethod(
    "noTitles",
    function(value, element) {
        value = value.trim();
        return (
            this.optional(element) ||
            /^((?!([Mm](rs|r|s|iss)|[Dd]r) )[\s\S]+)$/.test(value)
        );
    },
    "Please do not include your title in your name."
);

($ as any).validator.addMethod(
    "noDuplicates",
    function(value, element, param) {
        let inputElems = $(`[data-group="${param}"]`);
        let inputVals = [];

        inputElems.each((i, elem) => {
            let inputVal = $(elem).val() as string;

            if (inputVal) {
                inputVal = inputVal.split(" ").join("");
                inputVals.push(inputVal.toLowerCase());
            }
        });

        let deduped = inputVals.filter((v, i, a) => a.indexOf(v) === i);

        return inputVals.length === deduped.length;
    },
    "Tags are unique to a pet, so don't add the same pet twice!"
);

($ as any).validator.addMethod(
    "lettersOnly",
    function(value, element) {
        return this.optional(element) || /^[a-zA-Z ]+$/i.test(value);
    },
    "Letters only please"
);

($ as any).validator.addMethod(
    "hasLetters",
    function(value, element) {
        return this.optional(element) || /[a-zA-Z]/i.test(value);
    },
    "Please give your pet a name with letters in it."
);

($ as any).validator.addMethod(
    "alphanumeric",
    function(value, element) {
        return this.optional(element) || /^[\w ]+$/i.test(value);
    },
    "Letters and numbers only please"
);

($ as any).validator.addMethod(
    "placeName",
    function(value, element) {
        return (
            this.optional(element) ||
            /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/i.test(
                value
            )
        );
    },
    "This doesn't look quite right..."
);

($ as any).validator.addMethod(
    "addressLine",
    function(value, element) {
        return (
            this.optional(element) || /^[^!@€£#$%^*+={}|?<>`~\n]+$/i.test(value)
        );
    },
    "No special characters please"
);

($ as any).validator.addMethod(
    "alphanumericPunctuation",
    function(value, element) {
        return (
            this.optional(element) ||
            /^[A-Za-z0-9,\.\?\!\;\:\- ]+$/i.test(value)
        );
    },
    "Letters, numbers and punctuation only please"
);

($ as any).validator.addMethod(
    "waitForAttributes",
    function(value, element, params) {
        const hasOkAttribute = $(element).attr(params.ok) !== undefined;
        const hasWaitAttribute = $(element).attr(params.wait) !== undefined;
        return !hasWaitAttribute && hasOkAttribute;
    },
    "Please wait until the process is complete, or retry if it fails."
);
