import $ from "jquery";

const handleFeatures = () => {
  const activeClass = 'active'
  const featureItems = $(".list-features__item")
  const images = $(".list-features__images img")
  const mobileImages = $(".list-features__content > .features_list > img")

  let index = 0
  const total = featureItems.length

  setInterval(() => {
    featureItems[index].classList.remove(activeClass)
    images[index].classList.remove(activeClass)
    mobileImages[index].classList.remove(activeClass)

    index = index === total - 1 ? 0 : index + 1

    featureItems[index].classList.add(activeClass)
    images[index].classList.add(activeClass)
    mobileImages[index].classList.add(activeClass)
  }, 15 * 1000)
}

const handlePrice = () => {
  const activeClass = 'active'
  const switcher = $(".price__toggle > div")
  const premiumPriceElement = $(".premium_price > span")
  const premiumPlusPriceElement = $(".premium_plus_price > span")

  switcher.on('click', () => {
    const on = switcher.hasClass(activeClass);

    if (on) {
      switcher.removeClass(activeClass);
    } else {
        switcher.addClass(activeClass);
    }

    const prefix = premiumPriceElement.data("prefix");
    const premiumYearlyPerMonth = premiumPriceElement.data('yearly') / 12;
    const premiumPlusYearlyPerMonth = premiumPlusPriceElement.data('yearly') / 12;

    premiumPriceElement.text(
        on ? `${prefix}${premiumPriceElement.data('monthly')}` : `${prefix}${premiumYearlyPerMonth.toFixed(2)}`
    );
    premiumPlusPriceElement.text(
        on ? `${prefix}${premiumPlusPriceElement.data('monthly')}` : `${prefix}${premiumPlusYearlyPerMonth.toFixed(2)}`
    );
  })

}

export const setup = () => {
  handleFeatures()
  handlePrice()
}
