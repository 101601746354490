/** @format */

import { BumperComponent } from "./BumperComponent";

export class QuantitySelectComponent extends BumperComponent {
    public static selector: string = "quantity-select-component";

    public init(): void {
        this.quantityElem = this.componentElement.find("[data-quantity]");
        this.setupListeners();
    }

    public setupListeners(): void {
        this.quantityElem.on("change", event => {
            let newQuantity = parseInt(this.quantityElem.val() as string);

            if (newQuantity < this.quantity) {
                for (let i = this.quantity; i > newQuantity; i--) {
                    this.removeNameField();
                }
            } else if (newQuantity > this.quantity) {
                for (let i = this.quantity; i < newQuantity; i++) {
                    this.addNameField();
                }
            }

            this.emit("quantityChange", this.quantity);
            this.quantity = newQuantity;
        });
    }
}
