/** @format */

import { AbstractComponent } from "../AbstractComponent";
import { sleep } from "../toolkit";

export class StockLoaderComponent extends AbstractComponent {
    public static selector: string = "stock-loader-component";
    private loaderElem: JQuery<HTMLElement>;
    private outElem: JQuery<HTMLElement>;
    private inElem: JQuery<HTMLElement>;

    public init(): void {
        this.loaderElem = this.componentElement.find("[data-stock-loader]");
        this.outElem = this.componentElement.find("[data-stock-out]");
        this.inElem = this.componentElement.find("[data-stock-in]");
    }

    public async run(time: number = 12000): Promise<void> {
        this.outElem.hide();
        this.inElem.hide();
        this.loaderElem.show();
        await sleep(time * 0.35);
        this.loaderElem.hide();
        this.outElem.show();
        await sleep(time * 0.025);
        this.outElem.hide();
        this.loaderElem.show();
        await sleep(time * 0.25);
        this.loaderElem.hide();
        this.inElem.show();
        await sleep(time * 0.375);
    }
}
