/** @format */

import $ from "jquery";
import { AbstractComponent } from "../../AbstractComponent";
import {
    sendAuthCode,
    sendAuthCodeNoLogin,
    verifyAuthCode,
    verifyAuthCodeAndLogin,
} from "../../requests/post";
import { getContext, getUrlParam } from "../../toolkit";

export class VerifyIdentityComponent extends AbstractComponent {
    public static selector: string = "verify-identity-component";
    private inputs: JQuery<HTMLElement>;
    private submitButton: JQuery<HTMLElement>;
    private resendButton: JQuery<HTMLElement>;
    private skip: boolean = false;
    private noLogin: boolean = false;

    public init(): void {
        this.inputs = this.componentElement.find("input");
        this.submitButton = this.componentElement.find(
            "[data-submit-verification]"
        );
        this.resendButton = this.componentElement.find(
            "[data-resend-verification]"
        );

        if (this.componentElement.data("skip") != null) {
            this.skip = true;
        }

        if (this.componentElement.data("no-login") != null) {
            this.noLogin = true;
        }

        this.setupListeners();
    }

    private setupListeners(): void {
        this.inputs.on("keyup", event => {
            if (this.skip) {
                let currentInput = $(event.target);
                let nextInput = currentInput.next("input");

                if (
                    (currentInput.val() as string).length === 1 &&
                    nextInput.length
                ) {
                    nextInput.focus();
                }
            }

            if (this.getCode().length === 6) {
                this.submitButton.removeAttr("disabled");
            } else {
                this.submitButton.attr("disabled", "disabled");
            }
        });

        this.submitButton.on("click", async () => {
            if (this.submitButton.attr("disabled") === "disabled") {
                return;
            }

            const code = this.getCode();
            let responseData: object;

            if (this.noLogin) {
                const emailInput = $("input[name=email]");
                const nonce = getUrlParam("nonce");
                responseData = await verifyAuthCodeAndLogin(
                    emailInput.val() as string,
                    code,
                    nonce
                );
            } else {
                responseData = await verifyAuthCode(code);
            }

            if (responseData["result"]) {
                const redirectUrl = this.noLogin
                    ? responseData["url"]
                    : getUrlParam("url");
                window.location.href = redirectUrl;
            } else {
                alert("Invalid code");
            }
        });

        this.resendButton.on("click", async () => {
            if (this.noLogin) {
                let emailInput = $("input[name=email]");
                await sendAuthCodeNoLogin(emailInput.val() as string);
            } else {
                await sendAuthCode();
            }
        });
    }

    private getCode(): string {
        let vals = this.inputs.map((index, input) => {
            return $(input).val();
        });

        return vals.toArray().join("");
    }
}
