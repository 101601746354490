/** @format */

import jQuery from "jquery";
import { AbstractComponent } from "../AbstractComponent";
import { verifyEmail } from "../requests/post";
import { debounce } from "../toolkit";

export class EmailVerificationComponent extends AbstractComponent {
    public static selector: string = "email-verification-component";
    private emailElm: JQuery<HTMLElement>;
    private loaderElm: JQuery<HTMLElement>;
    private errorLabelElm: JQuery<HTMLElement>;
    private deliverability: Boolean;
    private autocorrect: string;

    private WELLKNOWN_DOMAINS = [
        "hotmail.com",
        "hotmail.co.uk",
        "gmail.com",
        "googlemail.com",
        "yahoo.com",
        "yahoo.co.uk",
        "icloud.com",
        "aol.com",
        "aol.co.uk",
        "outlook.com",
        "live.com",
        "live.co.uk",
        "msn.com",
        "btinternet.com",
        "talktalk.net",
        "sky.com",
        "me.com",
        "ymail.com",
        "mail.com",
        "ntlworld.com",
        "comcast.net",
        "virginmedia.com",
        "rocketmail.com",
        "blueyonder.co.uk",
    ];

    public init(): void {
        this.emailElm = this.componentElement.find("[name=email]");
        this.loaderElm = this.componentElement.find("[data-email-loader]");

        this.setupListeners();
    }

    private setupListeners(): void {
        const debounceCheckEmail = debounce(this.checkEmail.bind(this));

        this.emailElm.on("keydown", () => {
            debounceCheckEmail.apply(this);
        });
    }

    private startEmailChecking() {
        this.emailElm.attr("data-email-checking", "true");
        this.loaderElm.show();
    }

    private stopEmailChecking() {
        this.emailElm.removeAttr("data-email-checking");
        this.loaderElm.hide();
    }

    private showErrorLabel(): void {
        const fieldName = this.emailElm.prop("name");

        if (!this.errorLabelElm) {
            this.errorLabelElm = this.emailElm.siblings(
                `label[id=${fieldName}-error]`
            );
        }

        if (this.errorLabelElm.length == 0) {
            this.errorLabelElm = jQuery(document.createElement("label"));
            this.errorLabelElm
                .addClass("error")
                .prop("id", `${fieldName}-error`)
                .prop("for", fieldName)
                .insertAfter(this.emailElm);
        }

        this.emailElm
            .removeAttr("data-email-ok")
            .removeClass("valid")
            .addClass("error");
    }

    private hideErrorLabel() {
        if (this.errorLabelElm && this.errorLabelElm.length > 0) {
            this.errorLabelElm.css("display", "none");
        }
        this.stopEmailChecking();
    }

    private showAutoCorrect() {
        const fieldName = this.emailElm.prop("name");

        if (!this.errorLabelElm) {
            this.errorLabelElm = this.emailElm.siblings(
                `label[id=${fieldName}-error]`
            );
        }

        if (this.autocorrect) {
            this.errorLabelElm.text(`Did you mean ${this.autocorrect}?`);
        } else {
            this.errorLabelElm.text("Please enter a valid email address.");
        }
        this.errorLabelElm.css("display", "block");
    }

    private isValidEmailFormat(email: string): Boolean {
        return /^((([a-zA-Z]|\d|[!#\$%&amp;&#39;\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&amp;&#39;\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-zA-Z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/.test(
            email
        );
    }

    private isBelongWellknownEmailService(email: string): Boolean {
        return this.WELLKNOWN_DOMAINS.includes(email.split("@")[1]);
    }

    private async checkEmail() {
        this.emailElm.removeAttr("data-email-ok");

        const email = <string>this.emailElm.val();
        const fieldName = this.emailElm.prop("name");

        if (!fieldName || !email || this.emailElm.attr("data-email-checking")) {
            return;
        }

        this.startEmailChecking();

        if (!this.isValidEmailFormat(email)) {
            this.showErrorLabel();
            this.stopEmailChecking();
            return;
        }

        if (this.isBelongWellknownEmailService(email)) {
            this.emailElm
                .attr("data-email-ok", "true")
                .addClass("valid")
                .removeClass("error");

            this.hideErrorLabel();
            this.stopEmailChecking();

            return;
        }

        let result: any;
        try {
            result = await verifyEmail(email);
        } catch (error) {
            this.showErrorLabel();
            this.stopEmailChecking();
            return;
        }

        this.deliverability = <boolean>result.deliverability;
        this.autocorrect = <string>result.autocorrect;

        if (this.deliverability) {
            this.emailElm
                .attr("data-email-ok", "true")
                .addClass("valid")
                .removeClass("error");

            this.hideErrorLabel();
        } else {
            this.showErrorLabel();
        }

        if (this.autocorrect) {
            this.showAutoCorrect();
        }

        this.stopEmailChecking();
    }
}
