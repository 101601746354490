/** @format */

import $ from "jquery";
import { ComponentLoader } from "../AbstractComponent";
import { CountdownComponent } from "../components/CountdownComponent";
import { LightboxComponent } from "../components/LightboxComponent";
import { firePixels, getContext, getUrlParam } from "../toolkit";
import { QrScannerComponent } from "../components/QrScannerComponent";
import { VerifyIdentityComponent } from "../account/components/VerifyIdentityComponent";
import "./menu";
import "./sidebar";
import { allowCookies, blockCookies } from "../requests/post";

new ComponentLoader([LightboxComponent, VerifyIdentityComponent]);

let activeClass = "active";
let menuIcon = $("[data-hamburger]");
let mobileMenu = $("[data-menu-contents]");
let simpleMenu = mobileMenu.length && mobileMenu.is(".nav__simple");

menuIcon.on("click", () => {
    if (menuIcon.is(`.${activeClass}`)) {
        hideMenu();
    } else {
        showMenu();
    }
});

mobileMenu.on("click", () => {
    if (simpleMenu) {
        hideMenu();
    }
});

function showMenu() {
    menuIcon.addClass(activeClass);

    if (simpleMenu) {
        mobileMenu.slideDown();
    } else {
        mobileMenu.fadeIn();
    }
}

function hideMenu() {
    menuIcon.removeClass(activeClass);

    if (simpleMenu) {
        mobileMenu.slideUp();
    } else {
        mobileMenu.fadeOut();
    }
}

let fixedHeader = document.querySelector(
    ".setup__header--fixed"
) as HTMLElement;

if (fixedHeader) {
    $(window).on("scroll", () => {
        let scroll = $(window).scrollTop();

        if (scroll > 0) {
            $(fixedHeader).addClass("shadow");
        } else {
            $(fixedHeader).removeClass("shadow");
        }
    });
}

if (document.querySelector("[data-scroll-into-view], [data-scroll-to]")) {
    import("../scrolling").then(scrolling => {
        scrolling.run();
    });
}

let carouselElem = document.querySelector("[data-carousel]") as HTMLElement;

if (carouselElem) {
    import("../components/carousel").then(carousel => {
        carousel.setup(carouselElem);
    });
}

if (document.querySelector("[data-faq-question]")) {
    import("../components/faqs").then(faqs => {
        faqs.setup();
    });
}

if (document.querySelector("[data-login-form]")) {
    import("./login").then(login => {
        login.setup();
    });
}

if (document.querySelector("[data-lp]")) {
    import("./lp").then(lp => {
        lp.setup();
    });
}

if (document.querySelector("[data-add-to-cart]")) {
    $("[data-add-to-cart]").on("click", () => {
        let url = new URL("/checkout", window.location.href);
        let quantity = $("[data-quantity]")
            .first()
            .text();
        url.searchParams.append("quantity", quantity);

        if (getContext()["autocomplete_disabled"]) {
            url.searchParams.append("autocomplete", "0");
        }

        let shippingParam = getUrlParam("shipping");

        if (shippingParam) {
            url.searchParams.append("shipping", shippingParam);
        }

        let ttclid = getUrlParam("ttclid");

        if (ttclid) {
            url.searchParams.append("ttclid", ttclid);
        }

        window.location.href = url.toString();
    });
}

let showOrder = $("[data-show-summary]");
let orderBody = $("[data-order-body]");

showOrder.on("click", () => {
    if (showOrder.is(".active")) {
        showOrder.text("Show order summary");
        showOrder.removeClass("active");
        orderBody.slideUp();
    } else {
        showOrder.text("Hide order summary");
        showOrder.addClass("active");
        orderBody.slideDown();
    }
});

if (document.querySelector("[data-google-map]")) {
    import("../google_map").then(googleMap => {
        googleMap.setup();
    });
}

if (document.querySelector("[countdown-component]")) {
    new ComponentLoader([CountdownComponent]);
}

if (document.querySelector("[data-thankyou]")) {
    let thanksContext = getContext();
    firePixels(
        "CompletePayment",
        thanksContext["value"],
        thanksContext["email"]
    );
}

if (document.querySelector("[data-toggle]")) {
    import("./pricing").then(pricing => {
        pricing.setup();
    });
}

if (document.querySelector("[data-checkout-page]")) {
    import("./checkout").then(checkout => {
        checkout.setup();
    });
}

let contactForm = document.querySelector("[data-contact-form]") as HTMLElement;

if (contactForm) {
    import("./contact").then(contact => {
        contact.setup(contactForm);
    });
}

if (document.querySelector("[data-notification]")) {
    import("../components/notification").then(notification => {
        notification.setup();
    });
}

if (document.querySelector("[qr-scanner-component]")) {
    new ComponentLoader([QrScannerComponent]);
}

if (document.querySelector("[data-home-page]")) {
    import("./home").then(home => home.setup());
}

if (document.querySelector("[data-lp-7]")) {
    import("./7").then(page => page.setup());
}

if (document.querySelector("[data-order-page]")) {
    import("./order").then(page => page.setup());
}

if (document.querySelector("[data-testimonial-page]")) {
    import("./testimonial").then(page => page.setup());
}

let cookieBanner = $("[data-cookie-banner]");

$("[data-cookies-allow]").on("click", async () => {
    let html = await allowCookies();
    $("head").append(html);
    cookieBanner.hide();
});

$("[data-cookies-block]").on("click", async () => {
    await blockCookies();
    cookieBanner.hide();
});
